<template>
    <div :class="[flag? '': 'content-box']">
        <div :class="[flag? '': 'container']">
            <div :class="[flag? 'contentM': 'content']">
                <div v-if="!flag" class="content_row"><h4 class="global_head_title">订单详情</h4></div>
                <div class="content_row flex">
                    <label class="info" for="">订单编号：</label><div>{{order.osn}}</div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">客户名称：</label><div>{{order.corp_name}}</div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">跟进人：</label><div>{{order.staff_name}}</div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">报价方案：</label>
                    <a :href="url + '/scrm/index/Download?id=' + order.quotation"><div>{{order.quotation_name}}</div></a>
                    <a :href="url + '/scrm/index/Download?id=' + order.quotation">
                        <a-button type="primary"  v-if="order.quotation != 0" style="margin-left:10px;"><a-icon type="download" />下载</a-button>
                    </a>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">订单：</label>
                    <a :href="url + '/scrm/index/Download?id=' + order.contract"><div>{{order.contract_name}}</div></a>
                    <a :href="url + '/scrm/index/Download?id=' + order.contract">
                        <a-button type="primary"  v-if="order.contract != 0" style="margin-left:10px;"><a-icon type="download" />下载</a-button>
                    </a>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">订单金额：</label><div>{{order.amount}}元</div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">订单期限：</label><div>{{order.sign_time}} 至 {{order.end_time}}</div>
                </div>
                <div class="content_row flex">
                    <label class="info" for="">订单状态：</label><div>{{order.status}}</div>
                </div>
               
                <div class="content_row flex" v-for="(value,key,index) in order.orders" :key="index">
                    
                    <label class="info" for="">{{key}}：</label><div>{{value}}</div>
                </div>
                
                <!-- 回款列表 -->
                <div class="flex" :style="flag? '': 'align-items: normal'" :class="[flag? 'progres': 'content_row']">
                    <label class="info" for="">回款列表：</label>
                    <div :style="Boolean(orderId) == false ? 'width:80%' : ''" >
                        <a-button v-if="order.status == '新建' || order.status == '进行中'" type="primary" class="editable-add-btn" @click="addRecord(1)">添加收款</a-button>
                        <a-table :columns="paymentColumns" :data-source="order.payment" class="table" :bordered="true" :pagination="false" v-if="Boolean(orderId) == false">
                            <template slot="Action" slot-scope="text,record">
                                <a class="action del" href="javascript:;" @click="onDelete(record.id,1)">删除</a>
                            </template>
                        </a-table>
                    </div>
                </div>
                <a-list :data-source="order.payment" v-if="Boolean(orderId) == true">
                    <a-list-item slot="renderItem" slot-scope="item" >
                        <div style="width: 95%;margin: 0 auto">
                            <div class="content_rowM flex">
                                <div>收款批次:{{item.batch}}</div>
                                <div>收款金额:{{item.amount}}</div>
                            </div>
                            <div class="content_rowM flex">
                                <div>收款人:{{item.staff_uid}}</div>
                                <div>收款方式:{{item.method}}</div>
                            </div>
                            <div class="content_rowM flex">
                                <div>完成时间:{{item.created}}</div>
                                <div>收款时间:{{item.receipt_time}}</div>
                            </div>
                            <div class="content_rowM flex">
                                <div>收款备注:{{item.doc}}</div>
                            </div>
                            <div class="f_left">
                                <a class="action del" href="javascript:;" @click="onDelete(item.id,1)">删除</a>
                            </div>
                        </div>
                    </a-list-item>
                </a-list>
                <!-- 退款 -->
                <div class="flex" :style="flag? '': 'align-items: normal'"  :class="[flag? 'progres': 'content_row']">
                    <label class="info" for="">退款列表：</label>
                    <div :style="Boolean(orderId) == false ? 'width:80%' : ''" >
                        <a-button v-if="order.status == '新建' || order.status == '进行中'" type="primary" class="editable-add-btn" @click="addRecord(2)">添加退款</a-button>
                        <a-table :columns="refundColumns" :data-source="order.refund" class="table" :bordered="true" :pagination="false"  v-if="Boolean(orderId) == false">
                            <template slot="Action" slot-scope="text,record">
                                <a class="action del" href="javascript:;" @click="onDelete(record.id,2)">删除</a>
                            </template>
                        </a-table>
                    </div>
                </div>
                <a-list :data-source="order.refund" v-if="Boolean(orderId) == true">
                    <a-list-item slot="renderItem" slot-scope="item" >
                        <div style="width: 95%;margin: 0 auto">
                            <div class="content_rowM flex">
                                <div>退款批次:{{item.batch}}</div>
                                <div>退款金额:{{item.amount}}</div>
                            </div>
                            <div class="content_rowM flex">
                                <div>退款人:{{item.staff_uid}}</div>
                                <div>退款方式:{{item.method}}</div>
                            </div>
                            <div class="content_rowM flex">
                                <div>完成时间:{{item.created}}</div>
                                <div>退款时间:{{item.receipt_time}}</div>
                            </div>
                            <div class="f_left">
                                <a class="action del" href="javascript:;" @click="onDelete(item.id,2)">删除</a>
                            </div>
                        </div>
                    </a-list-item>
                </a-list>
                <a-modal v-model="visible" :title="title" @ok="handleOk()">
                    <div class="content_row flex">
                        <label class="info" for="">金额 <span class="redS">*</span></label>
                        <div class="content_right">
                            <a-input v-model="record.amount" size="large" placeholder="请输入金额" prefix="￥"/>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">{{methodText}}方式 <span class="redS">*</span></label>
                        <div class="content_right">
                            <a-radio-group name="radioGroup" v-model="record.method">
                                <a-radio v-for="(item,index) in methodArr" :value="index+1" :key="index">
                                    {{item}}
                                </a-radio>
                            </a-radio-group>
                        </div>
                    </div>
                    <div class="content_row flex" v-if="type == 1">
                        <label class="info" for="">收款时间 <span class="redS">*</span></label>
                        <div class="content_right">
                            <a-date-picker v-model="record.receipt_time" size="large"/>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">{{methodText}}人 <span class="redS">*</span></label>
                        <div class="content_right">
                            <a-select size="large" show-search option-filter-prop="children" v-model="record.staff_uid" placeholder="">
                                <a-select-option value="default">默认</a-select-option>
                                <a-select-option v-for="(item,index) in handlerList" :key="index" :value="item.userid">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                    <div class="content_row flex" v-if="type == 1">
                        <label class="info" for="">收款备注</label>
                        <div class="content_right">
                            <a-textarea :auto-size="{ minRows: 5, maxRows: 8 }" v-model="record.doc" placeholder="请输入收款备注"></a-textarea>
                        </div>
                    </div>
                </a-modal>

                <!-- 补充条款 -->
                <div class="flex" :style="flag? '': 'align-items: normal'" :class="[flag? 'progres': 'content_row']">
                    <label class="info" for="">补充条款：</label>
                    <div :style="Boolean(orderId) == false ? 'width:80%' : ''" >
                        <a-button v-if="order.status == '新建' || order.status == '进行中'" type="primary" class="editable-add-btn" @click="addReplenish">添加补充条款</a-button>
                        <a-table :columns="replenishColumns" :data-source="order.replenish" class="table" :bordered="true" :pagination="false"  v-if="Boolean(orderId) == false">
                            <template slot="Action" slot-scope="text,record">
                                <a class="action del" href="javascript:;" @click="onDelete(record.id,2)">删除</a>
                            </template>
                        </a-table>
                    </div>
                </div>
                <a-modal v-model="repVisible" title="添加补充条款" @ok="handleOk2()">
                    <div class="content_row flex">
                        <label class="info" for="">金额 <span class="redS">*</span></label>
                        <div class="content_right">
                            <a-input v-model="repRecord.amount" size="large" placeholder="请输入金额" prefix="￥"/>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">签订时间 <span class="redS">*</span></label>
                        <div class="content_right">
                            <a-date-picker v-model="repRecord.sign_time" size="large"/>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">报价方案</label>
                        <div class="content_right">
                            <a :href="url + '/scrm/index/Download?id='+ repRecord.quotation" v-if="repRecord.quotation">{{repRecord.quotationText}}</a>
                            <label class="fileLabel upFile" for="quotation"><a-icon type="upload" /> 上传 </label>
                            <input type="file" id="quotation" value="" @change="quoUpload($event)">
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">订单</label>
                        <div class="content_right">
                            <a :href="url + '/scrm/index/Download?id='+ repRecord.contract" v-if="repRecord.contract">{{repRecord.contractText}}</a>
                            <label class="fileLabel upFile" for="contract"><a-icon type="upload" /> 上传 </label>
                            <input type="file" id="contract" value="" @change="conUpload($event)">
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">跟进人 <span class="redS">*</span></label>
                        <div class="content_right">
                            <a-select size="large" show-search option-filter-prop="children" v-model="repRecord.staff_uid" placeholder="">
                                <a-select-option value="default">默认</a-select-option>
                                <a-select-option v-for="(item,index) in handlerList" :key="index" :value="item.userid">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                    <div class="content_row flex">
                        <label class="info" for="">概要</label>
                        <div class="content_right">
                            <a-textarea style="width:80%;margin:10px 0;" :auto-size="{ minRows: 5, maxRows: 8 }" v-model="repRecord.content" placeholder="请输入内容"></a-textarea>
                        </div>
                    </div>
                </a-modal>
                <a-list :data-source="order.replenish" v-if="Boolean(orderId) == true">
                    <a-list-item slot="renderItem" slot-scope="item" >
                        <div style="width: 95%;margin: 0 auto">
                            <div class="content_rowM flex">
                                <div>报价方案:{{item.quotation_name}}</div>
                                <div>订单:{{item.contract_name}}</div>
                            </div>
                            <div class="content_rowM flex">
                                <div>内容:{{item.content}}</div>
                                <div>金额:{{item.amount}}</div>
                            </div>
                            <div class="content_rowM flex">
                                <div>签订时间:{{item.sign_time}}</div>
                                <div>完成时间:{{item.created}}</div>
                            </div>
                            <div class="f_left">
                                <a class="action del" href="javascript:;" @click="onDelete(item.id,2)">删除</a>
                            </div>
                        </div>
                    </a-list-item>
                </a-list>
            </div>
        </div>
    </div>
</template>
<script>
// import $ from "jquery";
import {requestXml,getDateTime,isMobile,fileUpload,ajaxUrl} from '../../../assets/js/request';
var url,delUrl;
export default {
    props: {
        addFlag: {
            type: Boolean,
            default: false
        },
        orderId: {
            type: String
        }
    },
    data() {
        return {
            flag: true,
            order: {},

            url: ajaxUrl,
            // 订单id
            id: "",
            orderStatus: ["取消","新建","进行中","已完成"],

            visible: false,
            type: 1,  // type为1是收款 2是退款
            methodText: "",
            title: "",
            record: {
                oid: 0,
                amount: null,
                method: 1,
                receipt_time: "",
                staff_uid: "default",
                doc: "",
            },
            repVisible: false,
            repRecord: {
                oid: 0,
                amount: null,
                sign_time: "",
                quotation: 0,
                contract: 0,
                staff_uid: "default",
                content: ""
            },
            handlerList: [],

            method: 1,
            methodArr: ["公户打款","微信","支付宝","刷卡","现金"],
            paymentColumns:[
                { title: '收款批次', dataIndex: 'batch'},
                { title: '收款金额', dataIndex: 'amount'},
                { title: '收款人', dataIndex: 'staff_uid' },
                { title: '收款方式', dataIndex: 'method' },
                { title: '完成时间', dataIndex: 'created' },
                { title: '收款时间', dataIndex: 'receipt_time'},
                { title: '收款备注', dataIndex: 'doc'},
                {
                    title: '操作',
                    width: 100,
                    scopedSlots: { customRender: 'Action' },
                }
            ],
            refundColumns:[
                { title: '退款批次', dataIndex: 'batch'},
                { title: '退款金额', dataIndex: 'amount'},
                { title: '退款人', dataIndex: 'staff_uid' },
                { title: '退款方式', dataIndex: 'method' },
                { title: '完成时间', dataIndex: 'created' },
                { title: '退款时间', dataIndex: 'receipt_time'},
                {
                    title: '操作',
                    width: 100,
                    scopedSlots: { customRender: 'Action' },
                }
            ],
            replenish: 0,
            replenishColumns:[
                { title: '报价方案', dataIndex: 'quotation_name'},
                { title: '订单', dataIndex: 'contract_name'},
                { title: '内容', dataIndex: 'content' },
                { title: '金额', dataIndex: 'amount' },
                { title: '完成时间', dataIndex: 'created'},
                { title: '签订时间', dataIndex: 'sign_time' },
                {
                    title: '操作',
                    width: 100,
                    scopedSlots: { customRender: 'Action' },
                }
            ],
        };
    },
    mounted: function(){
        this.getfield();
        // this.getSet();
    },
   
    methods: {
        // 获取订单自定义字段
        getfield() {
            requestXml("/scrm/order/getfield","GET",(res) => {
                console.log(res)
                this.orderField = res;
                
        // 如果有id则是编辑 获取详情
        if(this.$route.query.id){
            this.id = this.$route.query.id;
            this.getOrderDetail();
            this.getStaffList();
        }
        
        if(this.$route.query.type){
            this.type = this.$route.query.type;
            if(this.type == 3){
                this.repVisible = true;
                this.visible = false;
            }else{
                this.repVisible = false;
                this.visible = true;
            }
        }
        if(isMobile()){
            this.flag = true;
        }else{
            this.flag = false;
        }
            })
        },
        // 获取订单详情
        getOrderDetail() {
            requestXml("/scrm/Order/getDetails","GET",(res) => {
                console.log(res)
                res.status = this.orderStatus[res.status];
                res.sign_time = getDateTime(res.sign_time);
                res.end_time = getDateTime(res.end_time);
                res.created = getDateTime(res.created);


                let obj = {};
                for(let key in res.orders) {
                    for(let i=0;i<this.orderField.length;i++){
                        if(key == this.orderField[i].name){
                            obj[this.orderField[i].text] = res.orders[key];
                        }
                    }
                }
                res.orders = obj;
                // 回款
                for(let i=0;i<res.payment.length;i++){
                    res.payment[i].method = this.methodArr[res.payment[i].method - 1];
                    res.payment[i].receipt_time = getDateTime(res.payment[i].receipt_time);
                    res.payment[i].created = getDateTime(res.payment[i].created);
                }
                // 退款
                for(let i=0;i<res.refund.length;i++){
                    res.refund[i].method = this.methodArr[res.refund[i].method - 1];
                    res.refund[i].receipt_time = getDateTime(res.refund[i].receipt_time);
                    res.refund[i].created = getDateTime(res.refund[i].created);
                }
                // 补充条款
                for(let i=0;i<res.replenish.length;i++){
                    res.replenish[i].sign_time = getDateTime(res.replenish[i].sign_time);
                    res.replenish[i].created = getDateTime(res.replenish[i].created);
                }
                this.order = res;
            },{id: this.id})
        },
        // 获取收款人
        getStaffList() {
            requestXml("/scrm/Staff/getMinList","GET",(res) => {
                this.handlerList = res;
                // console.log(this.handlerList)
            })
        },
        // 获取退款原因
        getSet(){
			requestXml("/scrm/setting/getSetting","GET",(res) => {
				console.log(res.invalid,888888)
			})
		},
        // 添加收款和退款
        addRecord(type){
            console.log(type)
            this.visible = true;
            this.type = type;
            if(type == 1){
                this.title = "添加收款";
                this.methodText = "收款";
                url = "/scrm/Order/addPayment";
            }else{
                this.title = "添加退款";
                this.methodText = "退款";
                url = "/scrm/Order/addRefund";
            }
        },
        handleOk(){
            this.record.oid = this.id;
            if(!this.record.amount){
                this.$message.warning('请填写金额');
                return false;
            }
            if(!this.record.method){
                this.$message.warning('请选择方式');
                return false;
            }
            if(this.type == 1){
                this.record.receipt_time = this.record.receipt_time._d.getTime();
                if(!this.record.receipt_time){
                    this.$message.warning('请选择收款时间');
                    return false;
                }
            }
            if(!this.record.staff_uid){
                this.$message.warning('请选择收款人');
                return false;
            }
            
            requestXml(url,"POST",(res) => {
                console.log(res)
                if(res == "error"){
                    this.visible = false;
                }else{
                    this.visible = false;
                    this.record = {
                        oid: 0,
                        amount: null,
                        method: 1,
                        receipt_time: "",
                        staff_uid: "default",
                        doc: "",
                    },
                    this.getOrderDetail();
                }
            },this.record)
        },
        // 删除
        onDelete(id,type){
            if(type == 1){
                delUrl = "/scrm/Order/delPayment";
            }else{
                delUrl = "/scrm/Order/delRefund";
            }
            requestXml(delUrl,"POST",() => {
                this.getOrderDetail();
            },{"id": id})
        },
        // 添加补充条款
        addReplenish(){
            this.repVisible = true;
        },
        handleOk2(){
            this.repRecord.oid = this.id;
            if(!this.repRecord.amount){
                this.$message.warning('请填写金额');
                return false;
            }
            if(!this.repRecord.sign_time){
                this.$message.warning('请选择签订时间');
                return false;
            }else{
                this.repRecord.sign_time = this.repRecord.sign_time._d.getTime();
            }
            
            requestXml("/scrm/Order/addReplenish","POST",(res) => {
                console.log(res)
                if(res == "error"){
                    this.repVisible = false;
                }else{
                    this.repVisible = false;
                    this.repRecord = {
                        oid: 0,
                        amount: null,
                        sign_time: "",
                        quotation: 0,
                        contract: 0,
                        content: ""
                    },
                    this.getOrderDetail();
                }
            },this.repRecord)
        },
        // 报价方案
        quoUpload(e){
            fileUpload(e,(res) => {
                console.log(res)
                this.repRecord.quotation = res.id;
                this.repRecord.quotationText = res.name;
            })
        },
        // 订单
        conUpload(e){
            fileUpload(e,(res) => {
                this.repRecord.contract = res.id;
                this.repRecord.contractText = res.name;
            })
        },
    }
};
</script>
<style scoped>
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
    .ant-list{
        padding: 0 3.5rem;
    }
    .ant-list .content_row,.ant-list .content_rowM{
        justify-content: space-between;
    }

    .ant-modal .content_row{
        margin: 2rem 0;
        font-size: 16px;
        align-items: center;
    }
    .ant-modal .content_row .info{
        width: 17rem;
        padding-right: 10px;
    }
    .ant-modal .content_row .content_right{
        width: calc(100vw - 25rem);
    }

    .ant-list .ant-list-item{
        background: #fff;
        border: 0;
        border-radius: 10px;
        margin: 2rem 0;
    }
    .contentM .content_row{
        align-items: center;
        height: 7rem;
        line-height: 7rem;
        padding: 0 2.5rem;
        /* font-weight: 700; */
        background: #fff;
        color: #000;
        border-bottom: 1px solid #eee;
    }
    .contentM .content_row .info{
        display: inline-block;
        /* width: 17rem; */
        text-align: right;
        padding-right: 2rem;
        font-weight: bold;
    }
    .contentM .content_rowM{
        padding: 1rem 0;
        color: #202020;
    }
    .progres{
        align-items: center;
        margin: 2.5rem 3.5rem;
    }
    /* input ,selsct */
    .ant-input,.ant-select,.ant-calendar-picker{
        width: calc(90vw - 20rem);
    }
    .ant-list .content_row{
        justify-content: space-between;
    }

    .fileLabel {
        line-height: 1.499;
        font-weight: 400;
        background-image: none;
        border: 1px solid #d9d9d9;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        width: 90px;
        height: 32px;
        line-height: 32px;
        padding: 5px 15px;
        /* font-size: 14px; */
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.65);
        margin: 0!important;
    }

    input[type="file"] {
        display: none;
    }
    @media screen and (min-width: 750px){
        .ant-modal .content_row .info{
            width: 200px;
            padding-right: 10px;
        }
        .content_row{
            margin: 15px 0;
        }
        .content_row .info{
            width: 90px;
            padding-right: 10px;
        }
        .content_row .content_right{
            width: auto;
        }
        .content .content_row .editable-add-btn{
            margin-bottom: 15px;
        }
        .content .content_row .ant-calendar-picker input{
            width: 100% !important;
        }
        /* input ,selsct */
        .ant-input,.ant-select,.ant-calendar-picker,.ant-input-affix-wrapper{
            width: 300px;
        }
        /* .ant-modal{
            width: 550px !important;
        } */
        .save .ant-btn{
            margin-left: 90px;
        }
    }
</style>
